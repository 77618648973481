<template>
    <component :is="tag" :class="headingClasses">
        <slot/>
    </component>
</template>

<script setup>
const headingClassesEnum = {
    main: 'text-3xl lg:text-5xl font-bold',
    subtitle: 'font-bold text-blue-700 text-base before:block before:h-0.5 before:w-8 before:bg-blue-700 before:rounded flex gap-1.5 items-center'
};

const props = defineProps({
    role: {
        type: String,
        default: 'main'
    },
    tag: {
        type: String,
        default: 'h1'
    }
});

const headingClasses = computed(() => headingClassesEnum[props.role]);
</script>
